import React from 'react'
import { Link } from 'react-router-dom'
import { FiHome, FiLogOut, FiUser } from 'react-icons/fi';
import { useUser } from '../contexts/UserContext';


function NavbarMobile() {

  const {user} = useUser()
  
  return (
    <>
    <div className='block sm:hidden'>
    <div className='fixed bottom-0 w-full h-[50px] bg-white  flex justify-evenly items-center border-t-2 border-slate-50 '>
        
        {
          user.profile.type === "Creator" && (
            <>
              <Link to={`/profile/${user.username}`}>
                <div className="flex flex-col items-center">
                  <FiUser size={30} className="text-slate-500" />
                </div>
              </Link>
            </>
          )
        }


        <Link to="/home">
        {/* <p className='font-poppinsLight font-bold text-slate-700 text-lg'>Home</p> */}
        <div className="flex flex-col items-center">
        <FiHome size={30} className="text-slate-500" />
      
      </div>
        </Link>
        <Link to="/logout">
        <div className="flex flex-col items-center">
        <FiLogOut size={30} className="text-slate-500" />
        
      </div>
        </Link>
    </div>
    </div>
    </>
  )
}

export default NavbarMobile