import { createContext, useContext, useState } from 'react';

// Crea il contesto
const UserContext = createContext();

// Provider del contesto: avvolge l'app e rende i dati utente accessibili ovunque
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null); // Stato per memorizzare i dati dell'utente

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

// Hook per accedere facilmente al contesto utente
export const useUser = () => useContext(UserContext);
