import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function PaymentStatusPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const REDIRECT_TIMEOUT = 2000; // Timeout di reindirizzamento (1 secondo)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paymentStatus = searchParams.get('payment_status'); // Stato del pagamento
    const creatorUsername = searchParams.get('creator_username'); // Nome del creator
    const receiver = searchParams.get('receiver'); // Nome del destinatario
  
    let redirectTimeout;
  
    if (paymentStatus === 'success' && creatorUsername && receiver) {
      // Reindirizza alla conversazione specifica
      redirectTimeout = setTimeout(() => {
        navigate(`/conversation/${creatorUsername}/${receiver}`);
      }, REDIRECT_TIMEOUT);
    } else {
      // Reindirizza alla home per altri stati
      redirectTimeout = setTimeout(() => {
        navigate('/home');
      }, REDIRECT_TIMEOUT);
    }
  
    return () => clearTimeout(redirectTimeout); // Pulisci il timeout al cambio di pagina
  }, [location, navigate]);
  

  const renderMessage = () => {
    const searchParams = new URLSearchParams(location.search);
    const paymentStatus = searchParams.get('payment_status');

    switch (paymentStatus) {
      case 'success':
        return <p className="text-green-600 text-center">Pagamento completato con successo! 🎉</p>;
      case 'failed':
        return <p className="text-red-600 text-center">Pagamento non riuscito. Riprova.</p>;
      case 'insufficient_funds':
        return <p className="text-orange-600 text-center">Fondi insufficienti sulla carta. Usa un altro metodo di pagamento.</p>;
      default:
        return <p className="text-gray-600 text-center">Stato del pagamento non riconosciuto. Riprova più tardi.</p>;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="p-6 bg-white rounded-lg shadow-md">
        {renderMessage()}
        <p className="text-gray-500 text-center mt-4">
          Sarai reindirizzato automaticamente...
        </p>
      </div>
    </div>
  );
}

export default PaymentStatusPage;
