import React, { useState, useEffect } from 'react';
import api from '../Api';
import { Link, useParams } from 'react-router-dom';
import logo from '../Img/2.png';
import { useUser } from '../contexts/UserContext';

function ViewConversations() {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedConvId, setSelectedConvId] = useState(null); // Identifica la conversazione per upload
  const [file, setFile] = useState(null); // File da caricare
  const [showConfirmDelete, setShowConfirmDelete] = useState(false); // Mostra/nasconde conferma eliminazione
  const [convToDelete, setConvToDelete] = useState(null); // ID della conversazione da eliminare
  const params = useParams();
  const { user } = useUser();

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await api.get(`/sparklingApi/${params.username}/conversations/`);
        setConversations(response.data);
      } catch (error) {
        console.error('Errore nel caricamento delle conversazioni:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchConversations();
  }, [params.username]);

  const handleDeleteClick = (convId) => {
    setConvToDelete(convId); // Memorizza la conversazione selezionata
    setShowConfirmDelete(true); // Mostra la finestra di conferma
  };

  const handleDeleteConversation = async () => {
    try {
      await api.delete(`/sparklingApi/conversations/${convToDelete}/`);
      setConversations((prevConvs) => prevConvs.filter((conv) => conv.id !== convToDelete));
      setShowConfirmDelete(false); // Nasconde la finestra di conferma
      setConvToDelete(null); // Resetta l'ID della conversazione da eliminare
    } catch (error) {
      console.error('Errore durante l\'eliminazione della conversazione:', error);
    }
  };

  const handleUploadToConversation = (convId) => {
    setSelectedConvId((prevId) => (prevId === convId ? null : convId)); // Mostra/nasconde l'area di upload
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async (convId) => {
    if (!file) {
      alert("Seleziona un file prima di caricare.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      await api.post(`/sparklingApi/upload_conversation/${convId}/`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert("File caricato con successo!");
      setFile(null);
      setSelectedConvId(null); // Nasconde l'area di caricamento dopo il caricamento
    } catch (error) {
      console.error("Errore nel caricamento del file:", error);
    }
  };

  if (loading) return <div>Caricamento conversazioni...</div>;

  return (
    <div className="mb-12 sm:grid sm:grid-cols-12">
      <div className="sm:col-span-2"></div>
      <div className="sm:col-span-8">
        {conversations.length > 0 ? (
          conversations.map((conv) => (
            <div key={conv.id} className="rounded-3xl shadow-lg text-start p-4 bg-slate-50 my-3">
              <div className="flex flex-row justify-between items-center">
                <div>
                  <p className="font-poppinsLight mb-2">Conversazione con:</p>
                  <p className="font-poppinsLight text-primary">
                    <b>{conv.receiver}</b>
                  </p>
                  <p className="font-poppinsLight text-sm">
                    {conv.description}
                  </p>
                </div>
                <Link to={`../../conversation/${conv.creator}/${conv.receiver}/`} key={`${conv.id}-link`}>
                  <div>
                    <img src={logo} className="h-12 " alt="Logo" />
                  </div>
                </Link>
              </div>
              <Link to={`../../conversation/${conv.creator}/${conv.receiver}/`} key={`${conv.id}-button`}>
                <div className="flex justify-center">
                  <button className="bg-primary font-poppinsLight text-white sm:w-1/2 w-full rounded-xl mt-2">
                    Vai alla Chat &gt;
                  </button>
                </div>
              </Link>
              {user.username === conv.creator && (
                <div className="flex justify-around mt-4">
                  <button
                    onClick={() => handleDeleteClick(conv.id)}
                    className="font-poppinsLight text-sm text-slate-700 px-2"
                  >
                    Elimina 
                  </button>
                  <button
                    onClick={() => handleUploadToConversation(conv.id)}
                    className="font-poppinsLight text-sm text-slate-700 px-2"
                  >
                    Aggiorna 
                  </button>
                </div>
              )}
              {selectedConvId === conv.id && (
                <div className="mt-4 flex flex-col items-center">
                  <input type="file" onChange={handleFileChange} className="mb-2" />
                  <button
                    onClick={() => handleFileUpload(conv.id)}
                    className="bg-primary font-poppinsLight text-white sm:w-1/2 w-full rounded-xl mt-2"
                  >
                    Aggiorna chat &gt;
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>Nessuna conversazione trovata.</p>
        )}
      </div>
      <div className="sm:col-span-2"></div>

      {/* Finestra di conferma per eliminare */}
      {showConfirmDelete && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-4 max-w-sm w-full text-center">
            <p className="font-poppinsLight text-lg mb-4">Sei sicuro di voler eliminare questa chat?</p>
            <div className="flex justify-around mt-4">
              <button
                onClick={() => setShowConfirmDelete(false)}
                className="bg-gray-300 font-poppinsLight text-slate-700 py-2 px-4 rounded-lg"
              >
                Annulla
              </button>
              <button
                onClick={() => {
                  handleDeleteConversation();
                  setShowConfirmDelete(false); // Nasconde la finestra dopo l'eliminazione
                }}
                className="bg-red-500 font-poppinsLight text-white py-2 px-4 rounded-lg"
              >
                Elimina
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewConversations;
