import React from 'react';
import Hero from '../components/hero';
import Description from '../components/description';
import Description2 from '../components/description2';
import { useEffect } from 'react';
import Motivational from '../components/motivational';
import Howitworks from '../components/howitworks';
import Final from '../components/final';
import { useState } from 'react';


function Landing() {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Considera mobile come schermi con larghezza < 768px
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Imposta la condizione iniziale

    return () => window.removeEventListener('resize', handleResize);
  }, []);
 

  return (
    <div className='min-h-screen'
    
    

    style={{
      backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='800' height='600' viewBox='0 0 800 600'%3E%3Cpath fill='none' stroke='%23D1D5DB' stroke-width='1.5' d='M20,200 Q150,50 400,200 T780,200'/%3E%3Cpath fill='none' stroke='%23D1D5DB' stroke-width='1.5' d='M20,400 Q150,550 400,400 T780,400'/%3E%3Cpath fill='none' stroke='%23D1D5DB' stroke-width='1.5' d='M20,100 Q400,50 780,100'/%3E%3Cpath fill='none' stroke='%23D1D5DB' stroke-width='1.5' d='M20,500 Q400,450 780,500'/%3E%3C/svg%3E\")",
      backgroundSize: isMobile ? 'contain' : 'cover',  // Cambia in base alla condizione
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
    }}
    
      
      
    >
      
      <Hero />
      <Description />
      <Description2 />
      <Howitworks />
      <Final />
    </div>
  );
}

export default Landing;