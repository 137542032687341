import React, { useState } from 'react';
import { useUser } from '../contexts/UserContext';

function MessageActions({
  messageId,
  reactions = [],
  comments = [],
  userId,
  onAddReaction,
  onAddComment,
  onDeleteComment,
  onReportMessage,
}) {
  const [showModal, setShowModal] = useState(false);
  const [showReactionMenu, setShowReactionMenu] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showReportPopup, setShowReportPopup] = useState(false); // Stato per il popup di segnalazione
  const [newComment, setNewComment] = useState('');

  const totalReactions = reactions.length;
  const { user } = useUser();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleAddReaction = (reactionEmoji) => {
    const reactionMapping = {
      '❤️': 'cuore',
      '😂': 'risata',
      '😮': 'stupore',
      '😢': 'triste',
      '😡': 'rabbia',
    };
    const reactionType = reactionMapping[reactionEmoji];

    const userReaction = reactions.find((reaction) => reaction.user === userId);

    if (userReaction?.tipo === reactionType) {
      onAddReaction(messageId, null);
    } else {
      onAddReaction(messageId, reactionType);
    }
    setShowReactionMenu(false);
  };

  const handleAddComment = () => {
    if (newComment.trim()) {
      onAddComment(messageId, newComment);
      setNewComment('');
      setShowCommentInput(false);
    }
  };

  const handleDeleteComment = (commentId) => {
    if (window.confirm('Sei sicuro di voler eliminare questo commento?')) {
      onDeleteComment(messageId, commentId);
    }
  };

  const handleReport = () => {
    setShowReportPopup(true); // Mostra il popup di conferma
  };

  const confirmReport = () => {
    onReportMessage();
    setShowReportPopup(false); // Nascondi il popup dopo l'invio
  };

  const cancelReport = () => {
    setShowReportPopup(false); // Nascondi il popup senza inviare la segnalazione
  };

  return (
    <div className="flex flex-col mt-1">
      {/* Totale reazioni e commenti */}
      <div
        className="flex justify-end text-gray-500 text-xs cursor-pointer mb-1"
        onClick={toggleModal}
      >
        💬 {comments.length} | ❤️😂😮😢😡 {totalReactions}
      </div>

{/* Modale per i dettagli */}
{showModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
    <div className="bg-white rounded-lg p-4 w-3/4 max-w-md flex flex-col max-h-[80vh]">
      {/* Pulsante per chiudere il modale */}
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-bold">Dettagli Reazioni e Commenti</h2>
        <button onClick={toggleModal} className="text-gray-500 hover:text-black">
          <span className="text-3xl">&times;</span>
        </button>
      </div>

      {/* Recap delle reazioni */}
      <div className="mb-4">
        <h3 className="text-sm font-bold">Reazioni</h3>
        <ul className="text-gray-700 mt-2">
          {Object.entries(
            reactions.reduce((acc, reaction) => {
              acc[reaction.tipo] = (acc[reaction.tipo] || 0) + 1;
              return acc;
            }, {})
          ).map(([reactionType, count]) => (
            <li key={reactionType} className="flex items-center">
              <span className="text-lg mr-2">
                {reactionType === 'cuore' && '❤️'}
                {reactionType === 'risata' && '😂'}
                {reactionType === 'stupore' && '😮'}
                {reactionType === 'triste' && '😢'}
                {reactionType === 'rabbia' && '😡'}
              </span>
              {count}
            </li>
          ))}
        </ul>
      </div>

      {/* Sezione Commenti con scrollbar */}
<div className="flex-1 overflow-y-auto">
  <h3 className="text-sm font-bold">Commenti</h3>
  <ul className="text-gray-700 mt-2">
    {comments.length > 0 ? (
      comments.map((comment) => (
        <li
          key={comment.id}
          className="border-b border-gray-300 py-2 flex items-center justify-between"
        >
          {/* Contenitore del commento e pulsante */}
          <div className="flex items-center w-full justify-between">
            {/* Contenitore del testo del commento */}
            <div
              className={`break-words ${
                comment.user_username === user.username ? 'max-w-[80%]' : 'w-full'
              }`}
            >
              <p>
                <strong>{comment.user_username}</strong>: {comment.testo}
              </p>
              <p className="text-xs text-gray-500">
                {new Date(comment.timestamp).toLocaleString()}
              </p>
            </div>

            {/* Pulsante Elimina */}
            {comment.user_username === user.username && (
              <button
                onClick={() => handleDeleteComment(comment.id)}
                className="ml-2 text-red-500 text-xs flex-shrink-0"
              >
                Elimina
              </button>
            )}
          </div>
        </li>
      ))
    ) : (
      <li className="text-gray-500">Nessun commento presente</li>
    )}
  </ul>
</div>






      {/* Input per nuovo commento, sempre visibile */}
      <div className="mt-4 flex items-center border-t pt-2">
        <textarea
          value={newComment}
          onChange={(e) => {
            setNewComment(e.target.value);
            // Regola automaticamente l'altezza del textarea
            e.target.style.height = "auto"; // Resetta l'altezza per calcolarla di nuovo
            e.target.style.height = `${e.target.scrollHeight}px`; // Imposta l'altezza in base al contenuto
          }}
          placeholder="Scrivi un commento..."
          className="border border-gray-300 rounded-md p-2 text-xs w-full resize-none overflow-hidden"
          rows="1" // Altezza minima
        />
        <button
          onClick={handleAddComment}
          className="ml-2 px-4 py-1 bg-primary h-8 text-white rounded-md text-xs"
        >
          Invia
        </button>
      </div>
    </div>
  </div>
)}






      {/* Popup di conferma per la segnalazione */}
      {showReportPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30">
          <div className="bg-white rounded-lg p-6 w-3/4 max-w-md text-center">
            <h2 className="text-lg font-bold mb-4">Sei sicuro di voler segnalare questo contenuto?</h2>
            <p className="text-sm text-gray-700 mb-6">
              Segnala questo contenuto se noti illegalità o violazioni dei termini e condizioni
              d'uso.
            </p>
            <div className="flex justify-between">
              <button
                onClick={cancelReport}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
              >
                Annulla
              </button>
              <button
                onClick={confirmReport}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Invia segnalazione
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Menù per aggiungere reazioni e commenti */}
      <div className="flex justify-center space-x-2 mt-2">
        <button
          onClick={() => setShowReactionMenu((prev) => !prev)}
          className="text-xs text-blue-500"
        >
          Like
        </button>
        <button
          onClick={() => setShowCommentInput((prev) => !prev)}
          className="text-xs text-blue-500"
        >
          Commenta
        </button>
        <button
          onClick={handleReport}
          className="text-red-500 text-xs hover:underline"
        >
          Segnala
        </button>
      </div>

      {/* Menù a tendina delle reazioni */}
      {showReactionMenu && (
        <div className="flex justify-center space-x-2 mt-1">
          {['❤️', '😂', '😮', '😢', '😡'].map((reaction) => (
            <span
              key={reaction}
              onClick={() => handleAddReaction(reaction)}
              className="cursor-pointer text-lg"
            >
              {reaction}
            </span>
          ))}
        </div>
      )}

      {/* Input per il commento nella lista principale dei messaggi */}
      {showCommentInput && (
        <div className="mt-2 flex items-center">
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Scrivi un commento..."
            className="border border-gray-300 rounded-md p-1 text-xs w-full"
          />
          <button
            onClick={handleAddComment}
            className="ml-2 text-blue-500 text-xs"
          >
            Invia
          </button>
        </div>
      )}
    </div>
  );
}

export default MessageActions;
