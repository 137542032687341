
import {Navigate, Route, Routes, BrowserRouter} from "react-router-dom"

import Landing from "./pages/Landing";
import Login from "./pages/Login";
import Registrati from "./pages/Registrati";
import Home from "./pages/Home"
import ProtectedRoute from "./components/ProtectedRoutes";
import NotApproved from "./pages/NotApproved";
import Profile from "./pages/Profile"

import { UserProvider } from './contexts/UserContext'; //
import ChatConversation from "./pages/ChatConversation";
import PaymentStatusPage from "./pages/PaymentStatusPage";


// funzioni per logout, pulisce localstorage.
function Logout() {
  localStorage.clear()
  return <Navigate to='/login'/>
}

// Per essere sicuri quando registriamo qualcuno che il localstorage è pulito
function RegisterAndLogout() {
  localStorage.clear()
  return <Registrati/>
}


function App() {
  return (
    
    <>
    <UserProvider>
      <Routes>
        <Route path='/' element={<Landing/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/registrati' element={<RegisterAndLogout/>}/>
        <Route path='/logout' element={<Logout/>}/>
        <Route path='/notapproved' element={<NotApproved/>}/>

        <Route path='/home' element={
          <ProtectedRoute>
            <Home/>
          </ProtectedRoute>
          }/>
          <Route path='/profile/:username' element={
          <ProtectedRoute>
            <Profile/>
          </ProtectedRoute>
          }/>
          <Route path='/conversation/:creator/:receiver' element={
          <ProtectedRoute>
            <ChatConversation/>
          </ProtectedRoute>
          }/>
          <Route path='/payment-status' element={
          <ProtectedRoute>
            <PaymentStatusPage/>
          </ProtectedRoute>
          
          }/>

      </Routes>
      </UserProvider>
    </>
    
  );
}

export default App;
