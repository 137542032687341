import React from 'react';
import { MdChat, MdOutlineFavoriteBorder, MdPeople, MdHandshake } from 'react-icons/md';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Description2() {
  // Rilevamento della visibilità del componente con il % della vista
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.3, // Il componente diventa visibile al %
  });

  // Varianti per l'animazione di dissolvenza
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.5, // L'effetto di dissolvenza avverrà una dopo l'altra
      },
    },
  };

  const fadeInVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8 },
    },
  };

  // Varianti per l'animazione da sinistra
  const textVariants = {
    hidden: { x: '-10vw', opacity: 0 },  // Inizia fuori dalla vista a sinistra
    visible: {
      x: 0,  // Arriva nella posizione finale
      opacity: 1,
      transition: { duration: 1, ease: 'easeOut' },  // Transizione fluida
    },
  };

  return (
    <div ref={ref} className='min-h-screen'>
      <div className='grid grid-cols-12'>
        {/* Colonna sinistra per il testo, per dispositivi medi e superiori */}
        <div className='md:col-span-2 col-span-1'></div>

        {/* Testo con animazione da sinistra, visibile su dispositivi medi e superiori */}
        <div className='md:col-span-4 md:block hidden'>
          <motion.p
            className='text-8xl font-poppinsBold text-slate-700'
            variants={textVariants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
          >
            Una marcia in più al vostro{' '}
            <span className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
              legame
            </span>
          </motion.p>
        </div>

        {/* Colonna per le card */}
        <div className='md:col-span-4 col-span-10 flex flex-col justify-center'>
          {/* Testo con animazione da sinistra, visibile su dispositivi più piccoli */}
          <motion.p
            className='text-6xl font-poppinsBold md:hidden block text-slate-700 text-center'
            variants={textVariants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
          >
            Una marcia in più al vostro{' '}
            <span className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
              legame
            </span>
          </motion.p>

          {/* Contenitore per le card */}
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            className='grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-center'
          >
            {/* Card 1 */}
              <motion.div
                variants={fadeInVariant}
                className="mt-8 md:mt-0 text-center text-slate-700 shadow-2xl rounded-lg md:w-[200px] md:h-[200px] w-[250px] h-[200px] flex flex-col justify-between bg-transparent p-3"
              >
                {/* Icona grande */}
                <div className="flex justify-center items-center text-primary mb-4">
                  <MdChat className="text-4xl" />
                </div>

                {/* Titolo */}
                <p className="text-xl font-poppinsBold mt-2">Leggi</p>

                {/* Descrizione */}
                <p className="text-xl font-poppinsLight mt-2">
                  le <b>chat</b> private che ti hanno condiviso
                </p>
              </motion.div>

              {/* Card 2 */}
              <motion.div
                variants={fadeInVariant}
                className="text-center text-slate-700 shadow-2xl rounded-lg md:w-[200px] md:h-[200px] w-[250px] h-[200px] flex flex-col justify-between bg-transparent p-3"
              >
                {/* Icona grande */}
                <div className="flex justify-center items-center text-primary mb-4">
                  <MdOutlineFavoriteBorder className="text-4xl" />
                </div>

                {/* Titolo */}
                <p className="text-xl font-poppinsBold mt-2">Interagisci</p>

                {/* Descrizione */}
                <p className="text-xl font-poppinsLight mt-2">
                  con ogni <b>messaggio</b> inviato
                </p>
              </motion.div>

              {/* Card 3 */}
              <motion.div
                variants={fadeInVariant}
                className="text-center text-slate-700 shadow-2xl rounded-lg md:w-[200px] md:h-[200px] w-[250px] h-[200px] flex flex-col justify-between bg-transparent p-3"
              >
                {/* Icona grande */}
                <div className="flex justify-center items-center text-primary mb-4">
                  <MdPeople className="text-4xl" />
                </div>

                {/* Titolo */}
                <p className="text-xl font-poppinsBold mt-2">Discutete</p>

                {/* Descrizione */}
                <p className="text-xl font-poppinsLight mt-2">
                  insieme di ogni <b>scelta di vita</b>
                </p>
              </motion.div>

              {/* Card 4 */}
              <motion.div
                variants={fadeInVariant}
                className="text-center text-slate-700 shadow-2xl rounded-lg md:w-[200px] md:h-[200px] w-[250px] h-[200px] flex flex-col justify-between bg-transparent p-3"
              >
                {/* Icona grande */}
                <div className="flex justify-center items-center text-primary mb-4">
                  <MdHandshake className="text-4xl" />
                </div>

                {/* Titolo */}
                <p className="text-xl font-poppinsBold mt-2">Formate</p>

                {/* Descrizione */}
                <p className="text-xl font-poppinsLight mt-2">
                  un <b>legame</b> profondo e sincero
                </p>
              </motion.div>

          </motion.div>
        </div>

        <div className='md:col-span-2 col-span-1'></div>
      </div>
    </div>
  );
}

export default Description2;
