import React from 'react';

function NotApproved({ email }) {
  return (
    <div className='font-poppinsLight text-center mt-10 text-slate-700'>
      <p className='font-poppinsBold'>Profilo in fase di approvazione</p><br/>
      <p>
        Stiamo revisionando il tuo profilo. <br/>Ti invieremo una notifica all'indirizzo email specificato non appena il processo sarà terminato.
      </p><br/>
      <p>In caso di necessità contattare il supporto a: <br/> support@sparklingapp.it</p><br/>
      
      
      
    </div>
  );
}

export default NotApproved;
