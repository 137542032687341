import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Final() {

    const [contattaci, setContattaci] = useState("Contattaci >")

    const handleContattaci = () => {
        if (contattaci === "Contattaci >") {
          setContattaci("sparklingplt@gmail.com")
        } else {
          setContattaci("Contattaci >")
        }
      
      }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center  px-4">
      {/* Titolo principale */}
      <p className="md:text-8xl text-6xl font-poppinsBold text-slate-700 text-center mb-8">
        Cosa aspetti?
      </p>

      {/* Sottotitolo */}
      <p className="md:text-5xl text-4xl text-slate-700 font-poppinsLight text-center mb-8">
        Unisciti anche tu alla 
        nostra <span className=" bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                community
            </span>
      </p>

      {/* Bottone per la registrazione */}
      <Link to="/registrati">
              <button className='bg-primary mb-8 text-white font-poppinsLight px-4 py-2 rounded-3xl hover:bg-transparent hover:border-2 hover:border-primary hover:text-primary hover:font-bold md:text-3xl text-xl  '> Registrati > </button>
            </Link>

      {/* Sottotitolo per le domande */}
      <p className="md:text-5xl text-4xl text-slate-700 font-poppinsLight text-center mb-4">
        Hai altre domande?
      </p>

      {/* Bottone per contattare */}
      <button onClick={handleContattaci} className=' font-poppinsLight px-4 py-2 mx-1 rounded-3xl bg-transparent   text-primary font-bold  md:text-3xl text-xl  '> {contattaci} </button>
    </div>
  );
}

export default Final;
