import React, { useState } from 'react'
import logo from '../Img/1.png'
import logo2 from '../Img/2.png'

import { Link } from 'react-router-dom'
function Hero() {

const [open, setOpen] = useState("false")
const [contattaci, setContattaci] = useState("Contattaci >")

const onClick = () => {
  if (open === "false") {
    setOpen("true")
  }
  if (open === "true") {
    setOpen("false")
  }

  
}

const handleContattaci = () => {
  if (contattaci === "Contattaci >") {
    setContattaci("sparklingplt@gmail.com")
  } else {
    setContattaci("Contattaci >")
  }

}

  return (
    
    <div className='min-h-screen '
    
    
    >
        
        <div className=' md:grid grid-cols-12 '>
            <div className='md:col-span-2 '></div>
            <div className='md:col-span-4 pt-3 flex flex-row md:justify-start justify-center '>
                <img src={logo} className='max-h-[50px]'></img>
                
            </div>
            <div className=' md:col-span-4 mt-3 flex flex-row md:justify-end align-middle'>
            <button onClick={handleContattaci} className=' font-poppinsLight px-4 py-2 mx-1 rounded-3xl bg-transparent   text-primary font-bold md:block hidden  '> {contattaci} </button>
            <Link to="/registrati">
              <button className='bg-primary text-white font-poppinsLight px-4 py-2 rounded-3xl hover:bg-transparent hover:border-2 hover:border-primary hover:text-primary hover:font-bold md:block hidden  '> Registrati > </button>
            </Link>
              
              
            </div>
            <div className='md:col-span-2'></div>
        </div>

        <div className='grid grid-cols-12 md:mt-20 mt-10 min-h-[500px]'>

            <div className='md:col-span-2 col-span-1'></div>

            <div className='md:col-span-5 col-span-10 '>
              <p className=' font-poppinsBold text-slate-700 md:text-5xl text-4xl'>
                <span className='text-6xl bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent'>Entra</span> nel cellulare dei 
                tuoi <span className='text-6xl bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent'>Influencer</span> e 
                <span className='text-6xl  bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent'>Vip</span> preferiti.
              </p>
              <p className=' text-slate-700 mt-8 text-xl font-poppinsLight'>
                <b> Sparkling</b> è la prima piattaforma al mondo per la condivisione empatica di contenuti personali. <br/>
                
              </p>
              <div className='flex flex-row md:mt-2 mt-6 justify-start '>
                  
                <Link to='/login'>
                <button className=' bg-primary font-poppinsLight rounded-3xl px-4 py-2 text-white text-md hover:bg-transparent hover:border-2 hover:font-bold hover:border-primary hover:text-primary'> Accedi subito ai contenuti > </button>
                </Link>
                  
              </div>

              {open === "true" &&
              <div className='bg-white shadow-md w-60 h-30 rounded-xl mt-4 p-2 text-slate-700'> 
                <div className='flex justify-end'>
                  <button className='font-poppinsBold' onClick={onClick}>X</button>
                </div>
              
              <p className='font-poppinsLight'> I contenuti al momento disponibili potrebbero non includere l'accesso alla galleria foto/video.</p>
              
              </div>}

             

              
            </div>

            <div className='md:col-span-4 md:flex md:flex-row md:justify-end col-span-1 '>
              <img src={logo2} className=' md:max-h-[500px] md:block hidden'></img>
            </div>

            <div className='md:col-span-1 hidden '></div>

        </div>





    </div>


    
    
    
    
    
  )
}

export default Hero