import { useState } from "react";
import React from 'react'
import api from "../Api";
import { useUser } from "../contexts/UserContext";


function UploadConversation() {

    const [receiverName, setReceiverName] = useState('');
    const [zipFile, setZipFile] = useState(null);
    const [receiverDesc, setReceiverDesc] = useState('')
    const [uploadMessage, setUploadMessage] = useState('');
    const { user } = useUser()

    const [uploadSelected, setUploadSelected] = useState("selected")
    const [chatSelected, setChatSelected] = useState()
    const [venditeSelected, setVenditeSelected] = useState()

    const [startDate, setStartDate] = useState(
      new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split("T")[0]
    );

    // Gestisce il caricamento del file
    const handleFileChange = (e) => {
      setZipFile(e.target.files[0]);
    };
  
    // Gestisce l'invio del form
    const handleSubmit = async (e) => {
      e.preventDefault();
      setUploadMessage("Loading...");
    
      if (!receiverName || !zipFile || !receiverDesc || !startDate) {
        setUploadMessage("Inserisci tutti i dati richiesti");
        return;
      }
    
      const formData = new FormData();
      formData.append("receiver_name", receiverName);
      formData.append("receiver_desc", receiverDesc);
      formData.append("file", zipFile);
      formData.append("start_date", startDate);
    
      try {
        const response = await api.post("/sparklingApi/upload_conversation/", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
    
        if (response.status === 201) {
          setUploadMessage("Conversazione caricata con successo!");
          setReceiverName("");
          setZipFile(null);
          setStartDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split("T")[0]);
        } else {
          setUploadMessage("Errore durante il caricamento");
        }
      } catch (error) {
        console.error("Errore:", error);
        setUploadMessage("Errore di connessione");
      }
    };
    
  return (
    <>
    <div className="min-h-fit bg-slate-50 p-6 flex flex-col items-center mb-8">
        <div className=" p-6 m-3 shadow-md rounded-lg bg-white w-full max-w-lg">
          
  
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="receiverName" className="block text-gray-700 font-poppinsBold mb-1">
                Nome del destinatario
              </label>
              <input
                type="text"
                id="receiverName"
                name="receiverName"
                value={receiverName}
                onChange={(e) => setReceiverName(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-primary"
                placeholder="Es. Andrea "
                required
              />
            </div>
            <div>
              <label htmlFor="startDate" className="block text-gray-700 font-poppinsBold mb-1">
                Data Inizio Chat
              </label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-primary"
                required
              />
            </div>
            <div>
              <label htmlFor="receiverName" className="block text-gray-700 font-poppinsBold mb-1">
                Descrizione
              </label>
              <input
                type="text"
                id="receiverDesc"
                name="receiverDesc"
                value={receiverDesc}
                onChange={(e) => setReceiverDesc(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-primary"
                placeholder="Es. Una nuova fiamma...🔥"
                required
              />
            </div>
           
            
            <div>


              <label htmlFor="zipFile" className="block text-gray-700 font-poppinsBold mb-1">
                Carica il file zip della conversazione
              </label>
              <input
                type="file"
                id="zipFile"
                name="zipFile"
                onChange={handleFileChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
                accept=".zip"
                required
              />
              {zipFile && <p className="mt-2 text-sm text-gray-600">File selezionato: {zipFile.name}</p>}
            </div>
  
            <button
              type="submit"
              className="w-full bg-primary text-white font-semibold py-2 rounded-lg hover:bg-primary-dark transition duration-200"
            >
              Carica Conversazione
            </button>
          </form>
  
          {uploadMessage && (
            <div className="mt-4 text-center text-gray-700">
              {uploadMessage}
            </div>
          )}
        </div>
      </div>
      </>
  );
}

export default UploadConversation