import { useState } from "react";
import React from 'react';
import api from "../Api";
import { useUser } from "../contexts/UserContext";
import { FiMessageCircle, FiUpload } from 'react-icons/fi';
import UploadConversation from "./uploadconversation";
import ViewConverstations from "./viewconverstations";
import ViewSales from "./viewsales";
import { useEffect, useRef } from "react";
import { BiDollar } from 'react-icons/bi';

function CreatorOwnerProfile() {
    const [receiverName, setReceiverName] = useState('');
    const [zipFile, setZipFile] = useState(null);
    const [uploadMessage, setUploadMessage] = useState('');
    const { user, setUser } = useUser();

    const [selectedIcon, setSelectedIcon] = useState('Upload');
    const [caption, setCaption] = useState('');

    const textareaRef = useRef(null);

    // Caricare la caption
    useEffect(() => {
        if (user?.profile?.caption) {
            setCaption(user.profile.caption);
            
        }
    }, [user]);

    const handleIconClick = (icon) => {
        setSelectedIcon(icon);
    };

    const handleFileChange = (e) => {
        setZipFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!receiverName || !zipFile) {
            setUploadMessage('Inserisci il nome del destinatario e seleziona un file');
            return;
        }

        const formData = new FormData();
        formData.append('receiver_name', receiverName);
        formData.append('file', zipFile);

        try {
            const response = await api.post('/sparklingApi/upload_conversation/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 201) {
                setUploadMessage('Conversazione caricata con successo!');
                setReceiverName('');
                setZipFile(null);
            } else {
                setUploadMessage('Errore durante il caricamento');
            }
        } catch (error) {
            console.error('Errore:', error);
            setUploadMessage('Errore di connessione');
        }
    };

    const handleInputChange = (e) => {
        setCaption(e.target.value);

        // Espande automaticamente l’altezza in base al contenuto
        e.target.style.height = "auto";
        e.target.style.height = `${e.target.scrollHeight}px`;
    };

    // Gestisce l'aggiornamento della caption al backend al momento del "blur"
    const handleBlur = async () => {
        if (caption.trim()) {
            try {
                const response = await api.put(`/sparklingApi/updateprofile/`, {
                    caption: caption
                });
                console.log("Caption aggiornata con successo:", response.data);

                setUser(prevUser => ({
                    ...prevUser,
                    profile: {
                        ...prevUser.profile,
                        caption: response.data.caption // Usa il valore dal backend
                    }
                }));
            } catch (error) {
                console.error("Errore nell'aggiornamento della caption:", error);
            }
        }
    };

    return (
        <div className="min-h-screen bg-white">
            <div className="grid grid-cols-12 sm:mt-8 mt-20 text-center">
                <div className="col-span-1"></div>
                <div className="col-span-10 flex flex-row">
                    <div className="rounded-full sm:h-36 sm:w-36 w-24 h-24 bg-slate-100 flex items-center justify-center font-poppinsLight text-slate-700 text-5xl">
                        {user.username.slice(0, 1)}
                    </div>

                    <div className="sm:pl-10 pl-3 flex flex-col items-start flex-grow">
                        <div className="text-xl font-poppinsLight">
                            {user.username}
                        </div>
                        <p className="font-poppinsLight">
                            Creator {user.type}
                        </p>

                        {/* Campo di testo per la caption */}
                        <textarea
                            type="text"
                            value={caption}
                            onChange={handleInputChange}
                            onBlur={handleBlur} // Aggiorna quando perde il focus
                            placeholder={user.caption || "Scrivi qui la tua caption"}
                            className="sm:block hidden w-full flex-grow p-2 border rounded-md focus:outline-none focus:border-blue-500 resize-none overflow-hidden"
                            rows={1}
                        />
                    </div>
                </div>
                <div className="col-span-1"></div>
            </div>

            <div className="grid grid-cols-12 text-start sm:hidden block">
                <div className="col-span-1"></div>
                <div className="col-span-10 m-2">
                    {/* Campo di testo per la caption in modalità mobile */}
                    <textarea
                        type="text"
                        value={caption}
                        onChange={handleInputChange}
                        onBlur={handleBlur} // Aggiorna quando perde il focus
                        placeholder={user.caption ? user.profile.caption : "Scrivi qui la tua caption e premi Invio"}
                        className="sm:hidden w-full h-fit p-4 border rounded-md focus:outline-none focus:border-blue-500 resize-none overflow-hidden"
                        rows={1}
                    />
                </div>
                <div className="col-span-1"></div>
            </div>

            <div className="grid grid-cols-11 mt-2 sm:mt-6 border-t-2 border-b-2 border-slate-50 text-center min-h-[60px] items-center">
                <div className="col-span-1"></div>
                <div className="col-span-3" onClick={() => handleIconClick('Upload')}>
                    <div className="flex flex-col items-center">
                        <FiUpload size={30} className={selectedIcon === 'Upload' ? 'text-primary' : 'text-gray-500'} />
                        <p className="sm:block hidden font-poppinsLight text-slate-700">Upload</p>
                    </div>
                </div>
                <div className="col-span-3" onClick={() => handleIconClick('Chat')}>
                    <div className="flex flex-col items-center">
                        <FiMessageCircle size={30} className={selectedIcon === 'Chat' ? 'text-primary' : 'text-gray-500'} />
                        <p className="sm:block hidden font-poppinsLight text-slate-700">Chat condivise</p>
                    </div>
                </div>
                <div className="col-span-3" onClick={() => handleIconClick('Vendite')}>
                    <div className="flex flex-col items-center">
                        <BiDollar size={30} className={selectedIcon === 'Vendite' ? 'text-primary' : 'text-gray-500'} />
                        <p className="sm:block hidden font-poppinsLight text-slate-700">Vendite</p>
                    </div>
                </div>
                <div className="col-span-1"></div>
            </div>

            <div className="grid grid-cols-12 text-center">
                <div className="col-span-1"></div>
                <div className="col-span-10 my-4 sm:mt-12 text-xl">
                    <p className="font-poppinsBold text-primary">
                        {selectedIcon}
                    </p>

                    {selectedIcon === 'Upload' && (
                        <>
                            <p className="font-poppinsLight text-lg mb-2">Carica una nuova chat e crea contenuti per i tuoi Fan</p>
                            <UploadConversation />
                        </>
                    )}
                    {selectedIcon === 'Chat' && (
                        <>
                            <p className="font-poppinsLight text-lg mb-2">Chat condivise con i tuoi fan</p>
                            <ViewConverstations />
                        </>
                    )}
                    {selectedIcon === 'Vendite' && (
                        <>
                            <p className="font-poppinsLight text-lg mb-2">Scopri quanto hanno generato le tue vendite</p>
                            <ViewSales />
                        </>
                    )}
                </div>
                <div className="col-span-1"></div>
            </div>
        </div>
    );
}

export default CreatorOwnerProfile;
