import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import logo from '../Img/2.png'
import { Link } from 'react-router-dom';

function CreatorCard({ username, instagramUrl, sharedChats, profileUrl }) {
  return (
    <div className="max-h-[500px] max-w-full m-4 bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 ease-in-out p-6 ">
      {/* Nome Utente */}
      <div className='flex flex-col items-center justify-center '>
      <div className="flex ">
        <a
          href= {`https://www.instagram.com/${instagramUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-gradient-to-r from-pink-500 to-yellow-500 p-2 rounded-full text-white hover:from-pink-600 hover:to-yellow-600 transition-colors duration-200 mr-2"
        >
          <FaInstagram size={30} />
        </a>
      </div>
      <Link to={`../profile/${username}`}>
      <p className="text-2xl font-poppinsLight mt-2 text-slate-700 text-center">{username}</p>
      </Link>
      </div>
      

      {/* Descrizione del Creator */}
      <p className="text-slate-700 font-poppinsLight text-center mt-4">Ha condiviso </p>

      {/* Dati sul Creator */}
      <div className="flex justify-center items-center space-x-4 mb-4">
        <div className="text-center">
          <p className="text-3xl font-poppinsBold text-primary">{sharedChats}</p>
          <p className="text-gray-700 text-sm font-poppinsLight ">chat con te </p>
        </div>
      </div>

      {/* Instagram Button */}
      <div className='grid grid-cols-12 text-center '>
        <div className='col-span-0'>

        </div>
        <div className='col-span-12'>
        <Link to={`../profile/${username}`}>
        <button className='bg-primary w-full text-white font-poppinsLight px-4 py-2 rounded-3xl hover:bg-transparent hover:border-2 hover:border-primary hover:text-primary hover:font-bold'> Vai ai contenuti ></button>
        </Link>
        </div>
        
        
        <div className='col-span-0'>
            
        </div>
      </div>
      
    </div>
  );
}

export default CreatorCard;
