import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useUser } from '../contexts/UserContext'
import CreatorOwnerProfile from '../components/creatorownerprofile'
import CreatorProfile from '../components/creatorprofile'
import api from '../Api'
import Nvabar from '../components/navbar'
import NavbarMobile from '../components/navbarmobile'
import Description from '../components/description'

function Profile() {

    const usernameparams = useParams()
    
    const {user} = useUser()
    const[isOwner, setIsOwner] = useState(false)
    const [type, setType] = useState([])
    

    useEffect(() => {
        const fetchProfileType = async () =>{
        try {
            const response = await api.get(`sparklingApi/fetchusertype/${usernameparams.username}/`);
            
            setType(response.data);
            

            if (user.username === usernameparams.username) {
                setIsOwner(true)
            }
            
            

          } catch (error) {
            alert(error)
            if (error.response && error.response.status === 404){
                setType("NotFound")
            } 
          }
        };
        fetchProfileType()
        
        


        
    }, [user, usernameparams])


    if ((type.type === "Fan") || (type === "NotFound")) {

        return(
            <>
            <div>Il profilo selezionato non appartiene a nesun creator</div>
            <Link to="/home">
                <div>Torna alla<u>Home</u> </div>
            </Link>
            </>
        )
    }

    if (type.type === "Creator" && isOwner && type.is_approved === true) {

        return(
            <>
            <Nvabar/>
            <CreatorOwnerProfile/>
            <NavbarMobile/>
            </>
        )
    }

    if (type.type === "Creator" && !isOwner && type.is_approved === true) {

        return(
            <>
                <Nvabar/>
                <CreatorProfile />
                <NavbarMobile/>
            </>
        )
    }
  
  
  
    return null
}

export default Profile