import React, { useState } from 'react'

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from '../contexts/UserContext';

import logo from '../Img/1.png'

import CreatorCard from '../components/creatorcard';
import Navbar from '../components/navbar';
import NavBarMobile from '../components/navbarmobile';
import api from '../Api';

const Home = ({}) => {

    const { user } = useUser();

    const[creators, setCreators] = useState([])

    useEffect(() => {

        const fetchCreators = async () => {

                try {

                    const res = await api.get("/sparklingApi/fetchcreators/")
                    setCreators(res.data)
                    
                    

                } catch (error) {
                    alert(error)
                }    

        }

        fetchCreators();

    }, [])
    
    return (
        <>
        <Navbar/>
        
      
        <div className=' grid grid-cols-12 sm:mt-8 mt-20'>
            <div className=' col-span-1'> 
            
            </div>
            <div className=' col-span-10 m-4 '> 

                <p className='font-poppinsLight text-slate-700 text-2xl'>Ciao <b>{user.username},</b></p> 
                <p className='font-poppinsBold text-primary text-3xl mt-2'>I tuoi Influencer e Creators preferiti ti stanno invitando a partecipare alla loro vita:</p>  

            </div>
            <div className=' col-span-1 '> 
            
            </div>

        </div>
        
        <div className=' grid grid-cols-12 mt-8'>
            <div className=' col-span-1'> 
            
            </div>
            <div className='  col-span-10  '> 

                <div className='grid sm:grid-cols-2 '>

                {creators && creators.length > 0 ? (
                    creators.map((creator) => (
                        <CreatorCard key={creator.username} username={creator.username} instagramUrl={creator.usernameig} sharedChats={creator.conversation_count} />
                        ))
                        ) : (
                        <p>Nessun creator trovato.</p>  // Messaggio di fallback se l'array è vuoto
                        )}
                
                    
                    
                    
                </div>

            </div>
            <div className=' col-span-1 '> 
            
            </div>

        </div>
        <br/>
        <br/>
        <NavBarMobile/>
        
        
        
        
            
        </>

    )
        
}
    export default Home;