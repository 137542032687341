import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Description = () => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.25,
  });

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const fadeInVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <div ref={ref} className="min-h-screen  ">
      <div className="grid grid-cols-12">
        {/* Colonna vuota sinistra */}
        <div className="md:col-span-2 col-span-1"></div>

        {/* Colonna centrale */}
        <div className="md:col-span-8 col-span-10">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
          >
            {/* Primo elemento */}
            <motion.div className="mt-6" variants={fadeInVariant}>
              <p className="md:text-8xl text-6xl font-poppinsBold text-slate-700 md:mt-14 text-center">
                I contenuti più
                <br />
                <span className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                  esclusivi
                </span>
              </p>
            </motion.div>

            {/* Secondo elemento */}
            <motion.div className="mt-10" variants={fadeInVariant}>
              <p className="md:text-5xl md:mt-10 text-4xl font-poppinsLight text-slate-700  rounded-lg p-4 text-center">
                Per i nostri Creators <b>sei importante</b>
              </p>
            </motion.div>

            {/* Terzo elemento */}
            <motion.div className="mt-10" variants={fadeInVariant}>
              <p className="md:text-3xl md:mt-16 text-xl font-poppinsLight text-slate-700 text-center">
                Per questo motivo hanno deciso di renderti partecipe del loro
                percorso di vita
              </p>
            </motion.div>
          </motion.div>
        </div>

        {/* Colonna vuota destra */}
        <div className="md:col-span-2 col-span-1"></div>
      </div>
    </div>
  );
};

export default Description;
