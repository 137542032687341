import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import React from 'react'
import api from "../Api";
import { useUser } from "../contexts/UserContext";
import {  FiMessageCircle, FiShoppingCart, FiUpload } from 'react-icons/fi';
import UploadConversation from "./uploadconversation";
import ViewConverstations from "./viewconverstations";
import ViewSales from "./viewsales";
import { Link } from "react-router-dom";
                  


function CreatorProfile() {


    

    
    const { user } = useUser()
    const usernameparams = useParams()
    const [profile, setProfile] = useState()
    const [selectedIcon, setSelectedIcon] = useState('Chat');
    
    const handleIconClick = (icon) => {
        setSelectedIcon(icon); // Cambia lo stato in base all'icona cliccata
      };
      
   
      useEffect(() => {
        const fetchProfileType = async () =>{
        try {
            const response = await api.get(`sparklingApi/fetchusertype/${usernameparams.username}/`);
            
            setProfile(response.data);          
            

          } catch (error) {
            alert(error)
            if (error.response && error.response.status === 404){
                setProfile("NotFound")
            } 
          }
        };
        fetchProfileType()
        
        


        
    }, [user, usernameparams])
    
    if (!profile) {
        // Mostra un indicatore di caricamento se profile è ancora undefined o null
        return <div>Caricamento del profilo...</div>;
    }

    if (profile === "NotFound") {
        return (
            <div>
                <p>Il profilo selezionato non appartiene a nessun creator.</p>
                <Link to="/home">
                    <div>Torna alla <u>Home</u></div>
                </Link>
            </div>
        );
    }


  
    return (

        <div className="min-h-screen bg-white">
            <div className="grid grid-cols-12 sm:mt-8 mt-20 text-center">
                <div className="col-span-1">

                </div>
                <div className="col-span-10 flex flex-row ">
                    <div className="rounded-full sm:h-36 sm:w-36 sm:h-max-36 sm:w-max-36  w-24 h-24 bg-slate-100 flex items-center justify-center font-poppinsLight text-slate-700 text-5xl">
                        {profile.username.slice(0,1)}
                    </div>

                    <div className=" sm:pl-10 pl-3 flex flex-col items-start ">
                        <div className="text-xl font-poppinsLight">
                            {profile.username}
                        </div>
                        <p className="font-poppinsLight">
                            Creator
                        </p>
                        <p style={{ whiteSpace: "pre-line" }} className="sm:block hidden mt-4 text-start">
                        {profile.caption}

                        </p>
                        
                    </div>

                </div>
                <div className="col-span-1">
                    
                </div>

            </div>


            <div className="grid grid-cols-12 text-start sm:hidden block">
                <div className="col-span-1">

                </div>
                <div className="col-span-10 m-2 ">
                   
                   <p style={{ whiteSpace: "pre-line" }} className="break-words ">
                    {profile.caption}
                   </p>     
                    

                </div>
                <div className="col-span-1">
                    
                </div>

            </div>

            <div className="grid grid-cols-11 mt-2 sm:mt-6 border-t-2 border-b-2 border-slate-50 text-center min-h-[60px] items-center">
                <div className="col-span-1 ">
                    
                </div>
                <div className="col-span-9 " >
                    
                <div className="flex flex-col items-center">
                        <FiMessageCircle size={30} className={selectedIcon === 'Chat' ? 'text-primary' : 'text-gray-500'} />
                        <p className=" font-poppinsLight text-slate-700">Le mie chat</p>
                    </div>
                        
                    

                </div>
               
                <div className="col-span-1 ">
                    
                </div>

            </div>

            <div className="grid grid-cols-12 text-center">
                <div className="col-span-1">

                </div>
                <div className="col-span-10 my-4 sm:mt-12 text-xl ">
                    
                      

                  
                   {
                    selectedIcon === 'Chat' &&
                    <>
                    <p className="font-poppinsLight text-lg my-2">Esplora le chat che ho deciso di condividere con te</p>
                    <ViewConverstations/> 
                    </>

                    
                   }
                   {
                    selectedIcon === 'Vendite' &&
                    <>
                    <p className="font-poppinsLight text-lg mb-2">Scopri quanto hanno generato le tue vendite</p>
                    <ViewSales/>
                    </>
                    
                    
                   }
                    

                </div>
                <div className="col-span-1">
                    
                </div>

            </div>










        </div>



    
    
     );
  }
 
export default CreatorProfile
  