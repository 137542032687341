import React, { useState, useEffect } from "react";
import api from "../Api";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function ViewSales() {
  const [sales, setSales] = useState([]);
  const [summary, setSummary] = useState({ total_sales: "0.00", total_creator_earnings: "0.00", number_of_sales: 0 });
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const fetchCreatorSales = async (filters = {}) => {
    try {
      const response = await api.get("/sparklingApi/creator-sales/", { params: filters });
      return response.data;
    } catch (error) {
      console.error("Errore durante il recupero delle vendite:", error);
      throw error;
    }
  };

  useEffect(() => {
    const applyFilter = async () => {
      setLoading(true);
      try {
        const filters = {};
        if (startDate) filters.start_date = startDate;
        if (endDate) filters.end_date = endDate;

        const data = await fetchCreatorSales(filters);

        // Ordina le vendite per data decrescente per la tabella
        const sortedSales = data.sales.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setSales(sortedSales);

        setSummary(data.summary);

        // Aggiorna il grafico (lascia l'ordine crescente)
        updateChartData(data.sales);
      } catch (error) {
        console.error("Errore durante il caricamento delle vendite filtrate:", error);
      } finally {
        setLoading(false);
      }
    };

    applyFilter();
  }, [startDate, endDate]);

  const handleQuickFilter = (type) => {
    const now = new Date();
    let start, end;

    if (type === "last_month") {
      const firstDayLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 2);
      const lastDayLastMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      start = firstDayLastMonth.toISOString().split("T")[0];
      end = lastDayLastMonth.toISOString().split("T")[0];
    } else if (type === "this_month") {
      const firstDayThisMonth = new Date(now.getFullYear(), now.getMonth(), 2);
      start = firstDayThisMonth.toISOString().split("T")[0];
      end = now.toISOString().split("T")[0];
    } else {
      start = "";
      end = "";
    }

    setStartDate(start);
    setEndDate(end);
  };

  const groupSalesByDay = (salesData) => {
    const dailySales = {};
    salesData.forEach((sale) => {
      const saleDate = new Date(sale.timestamp).toISOString().split("T")[0]; // Estrae solo la data
      if (!dailySales[saleDate]) {
        dailySales[saleDate] = 0;
      }
      dailySales[saleDate] += parseFloat(sale.creator_earnings);
    });
    return dailySales;
  };

  const updateChartData = (salesData) => {
    const dailySales = groupSalesByDay(salesData);
    const labels = Object.keys(dailySales)
      .sort((a, b) => new Date(a) - new Date(b)); // Ordina in modo crescente

    const data = labels.map((date) => dailySales[date]); // Mappa i valori delle vendite

    setChartData({
      labels,
      datasets: [
        {
          label: "Guadagni (€) per giorno",
          data,
          borderColor: "#4A5BFF",
          backgroundColor: "rgba(74, 91, 255, 0.2)",
          tension: 0.3,
          fill: true,
        },
      ],
    });
  };

  if (loading) {
    return <div>Caricamento in corso...</div>;
  }

  return (
    <div className="container mx-auto mt-2 p-4">
      {/* Riassunto vendite */}
      <div className="bg-gray-100 p-4 rounded-md mb-4 shadow-md">
        <p className="text-gray-600 font-poppinsLight">
          Totale vendite: <span className="font-poppinsBold">{summary.number_of_sales}</span>
        </p>
        <p className="text-gray-600 font-poppinsLight">
          Guadagno totale: <span className="font-poppinsBold">€{summary.total_creator_earnings}</span>
        </p>
      </div>

      {/* Filtri rapidi */}
      <div className="flex flex-wrap justify-center gap-4 mb-4">
        <button
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md sm:w-auto w-full"
          onClick={() => handleQuickFilter("last_month")}
        >
          Mese precedente
        </button>
        <button
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md sm:w-auto w-full"
          onClick={() => handleQuickFilter("this_month")}
        >
          Questo mese
        </button>
        <button
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md sm:w-auto w-full"
          onClick={() => handleQuickFilter("all")}
        >
          Tutto
        </button>
      </div>

      {/* Filtro date */}
      <div className="flex flex-wrap justify-center gap-4 mb-4">
        <div className="flex items-center">
          <label className="mr-2 text-gray-700 font-poppinsLight">Inizio:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="border border-gray-300 p-2 rounded-md"
          />
        </div>
        <div className="flex items-center">
          <label className="mr-2 text-gray-700 font-poppinsLight">Fine:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="border border-gray-300 p-2 rounded-md"
          />
        </div>
      </div>

      {/* Grafico */}
      <div className="bg-white p-4 rounded-md mb-4 shadow-md">
        <h2 className="text-gray-700 font-poppinsLight mb-4">Andamento giornaliero delle vendite</h2>
        <Line data={chartData} />
      </div>

      {/* Tabella delle vendite */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-200 text-sm sm:text-base">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-2 py-1 sm:px-4 sm:py-2 font-poppinsLight">Fan</th>
              <th className="border border-gray-300 px-2 py-1 sm:px-4 sm:py-2 font-poppinsLight">Data (UTC)</th>
              <th className="border border-gray-300 px-2 py-1 sm:px-4 sm:py-2 font-poppinsLight">Importo (€)</th>
              <th className="border border-gray-300 px-2 py-1 sm:px-4 sm:py-2 font-poppinsLight">Guadagno (€)</th>
            </tr>
          </thead>
          <tbody>
          {sales.map((sale, index) => (
            <tr key={index} className="hover:bg-gray-50">
              <td className="border border-gray-300 px-2 py-1 sm:px-4 sm:py-2">{sale.fan_username}</td>
              <td className="border border-gray-300 px-2 py-1 sm:px-4 sm:py-2">
                {(() => {
                  const date = new Date(sale.timestamp);
                  const day = String(date.getUTCDate()).padStart(2, "0");
                  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
                  const year = date.getUTCFullYear();
                  const hours = String(date.getUTCHours()).padStart(2, "0");
                  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
                  return `${day}/${month}/${year} - ${hours}:${minutes}`;
                })()}
              </td>
              <td className="border border-gray-300 px-2 py-1 sm:px-4 sm:py-2">{sale.amount}</td>
              <td className="border border-gray-300 px-2 py-1 sm:px-4 sm:py-2">{sale.creator_earnings}</td>
            </tr>
          ))}

          </tbody>
        </table>
        <br/>
        <br/>
      </div>
    </div>
  );
}

export default ViewSales;
